@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;1,300&family=Lato&family=Orbitron&family=Roboto&display=swap');

body {
  font-weight: 400;
  overflow-x: hidden;
}

.login-page {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
}

.login-page h2 {
  font-family: 'Orbitron';
  color: #fff;
  font-size: 1.5em;
}

.login-page-container {
  height: 100vh;
  background: rgba(18,20,24,.8);
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border-radius: 0;
}

.login-submit-btn {
  background: #838f9d;
  border: 0;
  border-radius: 0;
  font-family: 'Lato';
}

.login-submit-btn:hover {
  background: #656e79;
}

.react-bootstrap-drawer {
  border-right: none !important;
  border-radius: 12px;
}

@media (min-width: 768px) {
  .react-bootstrap-drawer {
    height: 96vh !important;
  }
}

.react-bootstrap-drawer-toggle {
  text-align: left;
}

.react-bootstrap-drawer.admin-sidebar {
  background: linear-gradient(195deg, rgb(82 82 111), rgb(38 38 55));
}

/*@media (min-width: 1630px) {
  .admin-sidebar {
    width: 250px;
  }
}*/

.admin-sidebar .react-bootstrap-drawer-overflow .react-bootstrap-drawer-toc .nav-link:not(.react-bootstrap-drawer-toc-header) {
  color: rgba(235, 235, 235, .75);
}

.admin-sidebar .react-bootstrap-drawer-overflow .react-bootstrap-drawer-toc .nav-link:not(.react-bootstrap-drawer-toc-header):hover {
  color: rgba(235, 235, 235, 1);
}

.admin-sidebar-title {
  font-size: 1.2em;
  line-height: 1;
}

.admin-sidebar-subtitle {
  color: #8de8ff;
  font-size: .6em;
  font-weight: 600;
  text-transform: uppercase;
}

@media (max-width: 1799px) {
  .admin-sidebar-title {
    font-size: 14px;
  }

  .admin-sidebar-subtitle {
    margin-top: -18px;
    font-size: 10px;
  }
}

.react-bootstrap-drawer-toggle.admin-sidebar-toggle {
  color: #ebebeb !important;
}

.it-header-slim-wrapper.admin-navbar {
  background: #f0f2f5;
}

@media (max-width: 767px) {
  .it-header-slim-wrapper.admin-navbar {
    margin-top: -10px;
  }
}

@media (min-width: 768px) {
  .col-lg-2.admin-left-col {
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
  }

  .col-lg-10.admin-right-col {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    height: 100vh;
    overflow-y: scroll;
  }
}

.admin-wrapper {
  background: #f0f2f5;
}

.admin-navbar .container {
  max-width: 100%;
}

.admin-breadcrumb .breadcrumb .breadcrumb-item {
  color: rgb(52, 71, 103);
  font-size: .799em;
}

.admin-navbar div div div .it-header-slim-wrapper-content {
  align-items: baseline;
}

.admin-breadcrumb-home svg {
  opacity: .75;
  padding-bottom: 4px;
}

.admin-sidebar-list {
  padding-left: 10px;
}

.react-bootstrap-drawer-toc .nav-link:not(.react-bootstrap-drawer-toc-header).admin-sidebar-link {
  padding: 0.8rem 0.9rem;
  font-size: .779em !important;
}

.react-bootstrap-drawer-toc .nav-link:not(.react-bootstrap-drawer-toc-header).admin-sidebar-sublink {
  padding: 0.5em 0.8em !important;
  border-left: 1px solid #81b0bd;
}

.react-bootstrap-drawer-toc .admin-sidebar-link.nav-link.active {
  font-weight: 500;
  color: #fff !important;
  background: #1e1e28;
  border-radius: 10px;
  padding-bottom: 11px;
  padding-top: 11px !important;
}

.react-bootstrap-drawer-toc .admin-sidebar-link.nav-link.active-sub {
  font-weight: 500;
  color: #fff !important;
  background: #00566c;
  border-radius: 10px;
  padding-bottom: 11px;
  padding-top: 11px !important;
  border-left: none !important;
}

@media (max-width: 1799px) {
  .admin-sidebar .react-bootstrap-drawer-toc .admin-sidebar-link.nav-link {
    padding-left: 5px;
    padding-right: 0;
  }
}

.admin-logout, .admin-logged-user {
  font-size: .778em;
  cursor: pointer;
  text-transform: uppercase;
}

.text-admin-grey {
  color: rgb(52, 71, 103) !important;
}

.admin-logout-btn:hover {
  text-decoration: underline;
}

.it-access-top .icon.admin-logged-user-icon {
  width: 20px;
}

.sidebar-divider {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.08);
  border-right: 0px solid rgba(0, 0, 0, 0.08);
  border-left: 0px solid rgba(0, 0, 0, 0.08);
  height: 0.0625rem;
  margin: .4rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-color: transparent;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important;
}

.divider-dark {  
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.0625rem;
  margin: 1rem 0px;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0)) !important;
}

.admin-navbar div div div .it-header-slim-wrapper-content {
  padding-left: 0;
}

.admin-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  height: 100%;
  padding: 10px;
  padding-bottom: 40px;
}

.admin-card-header {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  opacity: 1;
  background: transparent;
  color: rgb(52, 71, 103);
  box-shadow: none;
}

.admin-card-icon {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  margin-top: -40px;
  opacity: 1;
  
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(64 64 64 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.black-gradient {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
} 

.teal-gradient {
  background: linear-gradient(195deg, rgb(0 114 143), rgb(0 51 64));
}

.purple-gradient {
  background: linear-gradient(195deg, rgb(140 100 221), rgb(48 19 110));
}

.grey-gradient {
  background: linear-gradient(195deg, rgb(157 157 157), rgb(68 70 72));
}

.light-blue-gradient {
  background: linear-gradient(195deg, rgb(35 193 229), rgb(46 85 120));
}

.link-list-wrapper ul li a.admin-card-link {
  font-size: .779em;
}

.admin-table {
  font-size: .779em;
}

.admin-table td {
  border: none;
  padding: .6em;
}

.btn-admin-dashboard-add {
  background: linear-gradient(265deg, rgb(0 135 79), rgb(14 100 63));
  color: #fff;
  position: absolute;
  bottom: -30px;
  left: 10px;
  right: 10px;
  font-size: .8em;
}

.btn-admin-dashboard-add:hover {
  color: #fff;
}

.btn-admin-add {
  background: linear-gradient(265deg, rgb(0 135 79), rgb(14 100 63));
  color: #fff;
  font-size: .8em;
  max-width: 300px;
}

.btn-admin-add:hover {
  color: #fff;
}

.btn-black-gradient {
  background: linear-gradient(265deg, rgb(58 58 64), rgb(29 29 30));
}

.btn-teal-gradient {
  background: linear-gradient(265deg, rgb(0 103 129), rgb(0 62 78));
}

.btn-purple-gradient {
  background: linear-gradient(265deg, rgb(111 75 186), rgb(59 29 123));
}

.admin-link {
  color: #0092b7;
}

.admin-link:hover {
  color: #003340;
}

.admin-card-view-more {
  font-size: .779em;
  position: absolute;
  bottom: 35px;
  right: 10px
}

.sidebar-item-name {
  display: inline-block;
}

@media (max-width: 767px) {
  .sidebar-item-name {
    display: inline-block;
  }
} 

@media (min-width: 768px) and (max-width: 1199px){
  .sidebar-item-name {
    display: none;
  }
}

.admin-form.form-group label, .css-zsohrp-placeholder, .admin-form-standard.form-group label {
  z-index: 0;
  font-size: .7777777778rem;
}

.admin-label {
  font-size: .777em;
  font-weight: 600;
  color: #435a70;
}

.admin-form-standard {
  margin-bottom: 2rem;
  font-size: .777rem;
}

.admin-form, .admin-form .css-sjawnh-control {
  border: 0 !important;
  margin-bottom: 2rem;
  font-size: .777rem;
}

.admin-form-standard .css-sjawnh-control {
  margin-bottom: 2rem;
}

.admin-img-preview {
  max-width: 100%;
  max-height: 100%;
}

.form-control-file {
  background: #fff;
  padding: 5px;
}

.btn-admin-primary {
  background: #0066cc;
  color: #fff;
}

.btn-admin-primary:hover {
  color: #fff;
  background-color: #0053a6;
  border-color: #004d99;
}

.admin-attachments-wrapper {
  align-items: baseline;
}

.img-attachments-wrapper {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
}

.img-delete-btn-wrapper {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #cd2a2a;
  width: 34px;
  height: 34px;
  text-align: center;
  border-radius: 10px;
  z-index: 99
}

.img-delete-btn-wrapper button {
  padding: 0;
  color: #fff;
  width: 100%;
  height: 90%;
}

.img-delete-btn-wrapper button:hover {  
  color: #fff;
}

.text-admin-danger {
  color: #fb0228!important;  
}

.btn-admin-success {
  background-color: #003340;
  border-color: #003340;
  color: #fff;
}

.btn-admin-success:hover {
  background-color: #004b5e;
  border-color: #004b5e;
  color: #fff;
}

.admin-page-title {
  vertical-align: middle;
}

.admin-page-title-wrapper {
  color: #2e323f;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-bottom: 1px solid !important;
  border-color: #d9364f !important;
  background-color: #fff;
}

.form-group small.form-text.invalid-feedback {
  position: relative;
  margin-top: -25px;
  color: #d9364f !important;
}

.invalid-feedback-standalone {
  position: relative;
  margin-top: -45px;
  color: #d9364f !important;
  font-size: .777em;
  margin-bottom: 25px;
}

.admin-form.is-invalid, .admin-description-wrapper.is-invalid, .admin-form-standard.is-invalid {
  border-bottom: 1px solid !important;
  border-color: #d9364f !important;
}

.admin-form.admin-form-select.is-invalid, .admin-form-standard.admin-form-select.is-invalid {
  height: 46px;
}

.form-label {
  color: #435a70;
  font-size: .7777777778rem;
  display: block;
  font-weight: 600;
  padding: 0 0.5rem;
}

.tox.tox-tinymce {
  z-index: 0;
}

.admin-description-wrapper textarea, .admin-excerpt-wrapper textarea, .admin-more-info-wrapper textarea {
  visibility: hidden;
}

.modal-backdrop.show {
    opacity: .2;
}

.btn-simple {
  border: 0;
  background: transparent;
}

.admin-alert-success.alert.alert-success.fade.show {
  background: #d6f0e0;
  color: #0d6832;
  font-size: .775em;
  margin-top: -25px;
  margin-bottom: 3em;
  border-left: 0;
  padding: 1rem;
  border: none;
  border-radius: 10px;
}

.admin-alert-success button {
  color: #2d2c2c !important;
}

.admin-form-group .input-group input {
  border-bottom: 1px solid #ebebeb;
}

.admin-form-group .input-group label {
  font-size: .8rem;
}

.img-preview-box {
  background: #ebebeb;
  text-align: center;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px dashed #a5a5a5;
}

.item-wrapper {
  background:#f0f2f5;
  padding: 30px 10px 20px 10px;
  margin-bottom: 10px;
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
}

.item-remove-btn button {
  font-size: .777rem;
}

@media (min-width: 992px) {
  .item-remove-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.subitem-wrapper {
  background: #e3e6eb;
  padding: 10px;
}

.admin-sidebar {
  border-radius: 12px 0 0 12px !important;
  padding-right: 0 !important;
}

.admin-sidebar .react-bootstrap-drawer-toc {
  margin-right: 0 !important;
}

.admin-sidebar .react-bootstrap-drawer-toc::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.admin-sidebar .react-bootstrap-drawer-toc::-webkit-scrollbar-track {
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
}

.admin-sidebar .react-bootstrap-drawer-toc::-webkit-scrollbar-thumb {
  background: #00586e;
}

.file-drop-area {
  display: inline-block;
  padding: 10px;
  border: 2px dashed #ccc;
  cursor: pointer;
  width: 100%;
}

#mobile-navmenu {
  padding-left: 10px;
  margin-bottom: 20px;
}

.mobile-navmenu-logo-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.react-bootstrap-drawer-toc a .mobile-navmenu-logo-wrapper {
  margin-left: 20px;
}

@media(max-width: 1799px){
  .mobile-navmenu-logo img {
    width: 20px;
  }
}

.badge {
  border-radius: 1em;
  padding: 7px 20px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  min-width: 100px;
}

.badge-warning {
  background: #ff9627;
}

.badge-primary {
  background: #06c;
}

.badge-success {
  background: #00c563;
}

.contenzioso-wrapper {
  font-size: 16px;
}

.contenzioso-item {
  background: #fff;
  padding: 7px 10px;
  margin-bottom: 20px;
  border: 1px solid #f0f2f5;
  white-space: pre-line;
}

.contenzioso-wrapper-info {
  font-size: 14px;
}

.contenzioso-item-title {
  font-size: 14px;
  color: #868699;
  margin-bottom: 10px;
}

.attachment-icon {
  fill: #009688;
}

.form-group input, .form-group optgroup, .form-group textarea {
  font-weight: 500;
}

.form-group label, .form-group label.active {
  color: #868699;
}

.modal .modal-dialog .modal-content .modal-header .close {
  padding: 16px;
  background: transparent;
  border: none;
}

.modal .modal-dialog .modal-content .modal-header {
  padding-top: 0;
}

.adempimenti-sidebar {
  background: #dee3ef;
  padding: 20px;
  border-left: 5px solid #f0f2f5;
  margin-top: 15px;
  margin-bottom: 20px;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}

.green {
  color: #00ab56;
  font-weight: 600;
  border-bottom: 1px solid #d7d7d7;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
}

.red {
  color: #ff0039;
  font-weight: 600;
  border-bottom: 1px solid #d7d7d7;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 13px;
}

.progress {
  height: 10px;
  box-shadow: none;
  min-width: 100px;
  border-radius: 10px;
}

.bg-warning {
  background-color: #ff9b1f !important;
}

.poweredby {
  font-size: 15px;
  padding: 1.5rem 1rem;
  color: #999999 !important;
}

.poweredby a {
  color: #2f2f2f !important;
  text-decoration: none;
}

.login-sidebar-wrapper {
  flex-direction: column;
  align-content: stretch;
  height: 100%;
}

.login-organization-name {
  color: #bedfff;
  font-weight: 600;
  font-size: 1.5em;
  font-family: 'Titillium Web';
}

.prepend-box {
  padding: 10px 16px;
  font-size: .8em;
  background: #e1e0e3;
  border: 1px solid #d9d9d9;
  width: 60px;
  color: #757575;
}

.mail-message-wrapper {
  background: #fff;
  padding: 10px;
}

.table-header {
  background: #343439;
}

.table-header th {
  color: #fff;
}

.login-organization-name {
  color: #bedfff;
  font-weight: 600;
  font-size: 1.5em;
  font-family: 'Titillium Web';
}

button.section-header {
  border: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.accordion-body-wrapper {
  background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  font-size: .889em;
}

@media (min-width: 768px) {
  .accordion-body-wrapper {
    margin-right: 20px;
  }
}

.accordion-body-wrapper .collapse-body {
  padding-top: 6px;
  padding-bottom: 6px;
}

.accordion-action-wrapper {
  text-align: right;
}

.allegati-header {
  background: #696981;
  color: #fff;
  padding: 5px 10px;
  font-size: .8em;
  text-transform: uppercase;
}

.section-header {
  font-size: .8em;
  background:#3d3d43;
  color: #fff;
  padding: 12px 16px;
  text-transform: uppercase;
  position: sticky;
  top: 0;
  z-index: 999;
}

@media (min-width: 768px) {
  .section-header {
    margin-left: -20px;
  }
}

.section-wrapper {
  border-left: 3px solid #3d3d43;
}

@media (min-width: 768px) {
  .section-wrapper {
    padding-left: 8px;
  }
}